<template>
  <b-sidebar
    id="add-new-booking-sidebar"
    :visible="isEventHandlerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @hidden="$emit('hidden');"
    @change="(val) => $emit('one')"
  >
    <template>
<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add Booking') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="$emit('hidden')"
        />

      </div>



       <validation-observer
        ref="simpleRules"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
        <label class="section-label mb-1">{{ $t('Booking Details') }}</label>

        <validation-provider
            #default="validationContext"
            name="Client Name"
          >
            <b-form-group
              :label="$t('Client')"
              label-for="Client"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-if="guest === false"
                v-model="member_id"
                @search="fetchOptions"
                :options="memberOptions"
                :clearable="true"
                :filterable="false"
                placeholder="Please Enter Member Code..."
                input-id="Client"
                :reduce="member_id => member_id.value"
              >
              <template v-slot:spinner="{ loading }">
      <div v-show="loading">Loading...</div>
    </template>
    </v-select>
    <hr />
    <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              size="sm"
              type="button"
              block
              :disabled="processing"
              @click="guest = true;member_id = 'GUEST'"
            >
             GUEST 
            </b-button>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


 <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              rules=""

            >

              <b-form-group
                :label="$t('Start Date Time')"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="booking.start"
                  class="form-control"

                  :onChange="change_start()"
                  :config="{ mode:'single',maxDate:maxDate, enableTime:false, dateFormat: 'Y-m-d', minuteIncrement: '15'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group>
            <!--   <b-form-timepicker v-model="booking.start_time" :hour12="hour12" minutes-step="15"  reset-button /> -->
               <b-form-input
                 id="card"
                  v-mask="timeRangeMask"
                 v-model="booking.start_time"
                 trim
                 placeholder="HH:MM"
               />
             </b-form-group>
            <!-- End Date --><!--
            <validation-provider
              #default="validationContext"
              name="End Date"
              rules=""
            >

              <b-form-group
                label="End Date"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="booking.end"
                  disabled
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

-->
            <validation-provider
            #default="validationContext"
            name="room"
            rules="required"
          >
            <b-form-group
              :label="$t('Room')"
              label-for="room"
              :state="getValidationState(validationContext)"
            >
              <v-select
                 v-model="booking.room_id"
                :options="roomOptions"
                :clearable="true"
                  :reduce="room => room.id"
                :filterable="false"
                input-id="package"
              >
              <template v-slot:spinner="{ loading }">
      <div v-show="loading">Loading...</div>
    </template>
    </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
          #default="validationContext"
          name="staff"
          rules="required"
            v-if="$can('read', 'staff')"
        >
          <b-form-group
            :label="$t('Staff')"
            label-for="Staff"
            :state="getValidationState(validationContext)"
          >
            <v-select
               v-model="booking.staff_id"
              :options="staffOptions"
              :clearable="true"
              :filterable="true"
              input-id="package"
              :reduce="option => option.id"
            >
            <template v-slot:spinner="{ loading }">
    <div v-show="loading">Loading...</div>
  </template>
  </v-select>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
        #default="validationContext"
        name="Price"
      >
        <b-form-group
          :label="$t('Remarks')"
          label-for="remarks"
        >
        <b-form-input
          id="remarks"
          v-model="booking.remarks"
          trim
        />


          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>



 <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              :disabled="processing"
              @click="submit_now()"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              {{ $t('Back') }}
            </b-button>
          </div>
          <br><br><br><br><br><br><br><br>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormTimepicker, BFormInput, BFormInvalidFeedback, BButton, BModal, VBModal, BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'
import moment from 'moment'

export default {
  components: {
    ToastificationContent,
    BFormTimepicker,
    flatPickr,
    moment,
    BSidebar,
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal, VBModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewMemberSidebarActive',
    event: 'update:is-add-new-Member-sidebar-active'
  },
  props: ['isEventHandlerSidebarActive','bookingData'],
  computed: {
  },
  watch: {
    member_id : function (val) {
    //  this.booking.member_id = val
      this.fetchOptions_package()
    },
    rental_staff_id : function (val) {
    //  this.booking.member_id = val
      this.fetchOptions_staff_package()
    },
  },
  data() {
    return {
      processing: false,
      maxDate : new Date(),
      onContext: {
        "locale": "zh",
        "isRTL": false,
        "hourCycle": "h23",
        "hour12": false,
        "hours": null,
        "minutes": null,
        "seconds": null,
        "value": "",
      },
      rental_mode: false,
      memberOptions : [],
      userOptions : [],
      minute_step: null,
      hour12:false,
      guest : false,
      roomOptions : [],
      staffOptions : [],
      member_id: null,
      rental_staff_id: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      memberPackageOptions : [],
      userPackageOptions : [],
      required,
      paymentMethodOptions: [
        { label: 'Cash', value: 'cash' },
        { label: 'Visa', value: 'visa' },
      ],
      lock_quantity : true,
      alphaNum,
      email,
      countries,
      booking:{
        member_id:null,
        start:null,
        end:null,
        duration:60,
      },
      blankBookingData: {
        room_id:null,
        member_package_id:null,
        member_id:null,
        start:null,
        duration:60,
        end:null
      },

    }
  },
  created() {
    if (this.userData.role == "rental_pt") { this.rental_mode = true }
    this.maxDate.setMonth(this.maxDate.getMonth() + 1)
    //console.log(this.maxDate)
    this.get_rooms()
    this.get_staff()
  },
  methods: {
    timeMask(value) {
    const hours = [
      /[0-2]/,
      value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/,
    ];
    const minutes = [/[0-5]/, /[0-9]/];
    return value.length > 2
      ? [...hours, ':', ...minutes]
      : hours;
  },
    timeRangeMask(value) {
    const numbers = value.replace(/[^0-9]/g, '');
    if (numbers.length > 4) {
      return [...this.timeMask(numbers.substring(0, 4))];
    }
    return [...this.timeMask(numbers)];
  },

    change_start() {
      let duration = 60
      if (this.booking.member_package_id) {
        duration = this.booking.member_package_id.duration
      }
      var newdate = moment(this.booking.start,"YYYY-MM-DD HH:mm").add(duration , 'minutes').format('YYYY-MM-DD HH:mm')
      this.booking.end = newdate
      this.booking.duration = duration
    },
    get_staff() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/staffs/?staff&perpage=9999&page=1&onlyactive=true`)
        .then(res => {
          this.staffOptions = res.data.staffs
        //  console.log(this.staffOptions)
        })
    },
    get_rooms() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/rooms/?enabled=y&center_id=`+ this.$store.getters['center/currentCenterId'])
        .then(res => {
          this.roomOptions = res.data.rooms
          if (this.roomOptions.length == 1) {
            this.booking.room_id = this.roomOptions[0].id
          }
        })
    },
    fetchOptions (search, loading) {
      setTimeout(() => {
      this.$http.get(process.env.VUE_APP_API_BASE+'/search/member/?string='+search)
            .then(res => {
              this.memberOptions = res.data
            })
            }, 200);
      },
      fetchUserOptions (search, loading) {
        setTimeout(() => {
        this.$http.get(process.env.VUE_APP_API_BASE+'/search/staff/?string='+search)
              .then(res => {
                this.userOptions = res.data
              })
              }, 200);
        },
      fetchOptions_package (search, loading) {
      setTimeout(() => {
      this.$http.get(process.env.VUE_APP_API_BASE+'/member/'+this.member_id+'/packages/select/')
            .then(res => {
              this.booking.member_package_id = null
              this.memberPackageOptions = res.data.packages
            })
            }, 200);
      },
      fetchOptions_staff_package (search, loading) {
      setTimeout(() => {
      this.$http.get(process.env.VUE_APP_API_BASE+'/staff/'+this.rental_staff_id+'/packages/select/')
            .then(res => {
              this.booking.user_package_id = null
              this.userPackageOptions = res.data.packages
            })
            }, 200);
      },
    submit_now() {
      let mode = 'member';
      let id = null;
      if (this.rental_mode == true) {
        mode = 'staff'
        id = this.rental_staff_id
      } else {
        id = this.booking.member_id
      }
      if (this.guest === true) {
        this.member_id = 'guest'
        id = 'guest'
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
                this.processing = true
        //  console.log(this.booking)
          this.booking.member_id = this.member_id
          this.booking.rental_staff_id = this.rental_staff_id
        //  this.booking.room_id = this.booking.room_id.id
        //  this.booking.member_package_id = this.booking.member_package_id.member_package_id
          this.$http.post(process.env.VUE_APP_API_BASE+'/'+mode+'/'+id+'/booking/add/', this.booking)
            .then(res => {
              if (res.data.result === true) {
                  this.processing = false
                this.memberPackageOptions = []
                this.memberOptions = []
                this.member_id = null
                this.guest = false

                this.PackageOptions = []
                this.rental_staff_id = null

                this.booking.start = null
                this.booking.start_time = null
                this.booking.staff_id = null
                this.booking.remarks = null
                if (res.data.remarks) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-center',
                    props: {
                      title: 'Warning',
                      icon: 'StarIcon',
                      variant: 'warning',
                      text: res.data.remarks,
                    },
                  })
                }
                this.$emit('hidden')
                this.booking = this.blankBookingData
                this.$refs.simpleRules.reset()
              } else {
                  this.processing = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })
        }
      })
    },
  },
  setup(props, { emit }) {
    const blankPackageData = {
      expire_at:'',
      quantity:'',
      package: {
        expiry_figure:'1',
        expiry_unit:'DAY'
      }
    }

    const packageData = ref(JSON.parse(JSON.stringify(blankPackageData)))
    const resetuserData = () => {
      packageData.value = JSON.parse(JSON.stringify(blankPackageData))
    }

    const onSubmit = () => {

    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      packageData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
